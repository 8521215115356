import browserStorage from 'components/consumer/browser-storage';
import isClient from './isClient';
import isServer from './isServer';
import getCookies from './getCookies';
import {
  getUserLocationWithLongKeys,
  getUserLocationWithShortKeys,
} from './location';
import { USER_LOCATION_COOKIE_KEY, CJE_EVENT_COOKIE_KEY } from './constants';

const USER_ACCESS_TOKEN = 'uToken';
const USER_REFRESH_TOKEN = 'rToken';
const TEMP_USER_ID = 'tuID';
const USER = 'user';
const PALOMA_AFFILATE_REFERENCE = 'paloma_affilate_reference';

const returnCookieExpiry = () => {
  const currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 3);
  return currentDate;
};

export const clearVisitorCookies = () => {
  if (isClient()) {
    const cookieList = getCookies().getAll();
    if (cookieList) {
      Object.keys(cookieList).forEach(key => {
        getCookies().remove(key);
      });
    }
  }
};

export function setItemInStorage(item, value) {
  if (isClient()) {
    browserStorage().setItem(item, JSON.stringify(value));
  }
}

export function getItemFromStorage(item) {
  if (isClient()) {
    const value = browserStorage().getItem(item);
    return value ? JSON.parse(value) : null;
  }
  return null;
}

export function removeItemInStorage(item) {
  if (isClient()) {
    browserStorage().removeItem(item);
  }
}

export function setUserCookie(user) {
  getCookies().set(USER, JSON.stringify(user), {
    path: '/',
    maxAge: 2629746, // 1 month (30.4 days)
  });
}

export function getUserFromCookie() {
  return getCookies().get(USER);
}

export function setUserAccessTokenCookie(token) {
  // Store in Cookies
  getCookies().set(USER_ACCESS_TOKEN, token, {
    path: '/',
    expires: returnCookieExpiry(), // Current time + 3 hours
    maxAge: 10800, // 3 hours (180 mins)
  });
}

export function getUserAccessTokenCookie() {
  return getCookies().get(USER_ACCESS_TOKEN);
}

export function setUserRefreshTokenCookie(token) {
  // Store in Cookies
  getCookies().set(USER_REFRESH_TOKEN, token, {
    path: '/',
    maxAge: 2629746, // 1 month (30.4 days)
  });
}

export function getUserRefreshTokenCookie() {
  return getCookies().get(USER_REFRESH_TOKEN);
}

export function clearUserTokenCookies() {
  getCookies().remove(USER, { path: '/' });
  getCookies().remove(USER_ACCESS_TOKEN, { path: '/' });
  getCookies().remove(USER_REFRESH_TOKEN, { path: '/' });
}

export function setTempUserIdCookie(tempUID) {
  getCookies().set(TEMP_USER_ID, tempUID, {
    path: '/',
    maxAge: 31536000, // 1 year (365 days)
  });
}

export function getTempUserIdCookie() {
  return getCookies().get(TEMP_USER_ID);
}

export function clearTempUserIdCookie() {
  getCookies().remove(TEMP_USER_ID);
}

export function setUserLocationCookie(value) {
  if (value) {
    const userLocationWithShortKeys = getUserLocationWithShortKeys(value);

    getCookies()?.set(
      USER_LOCATION_COOKIE_KEY,
      JSON.stringify(userLocationWithShortKeys)
    );
  }
}

export function getUserLocationCookie() {
  const value = getCookies()?.get(USER_LOCATION_COOKIE_KEY);

  return getUserLocationWithLongKeys(value);
}

export function clearUserLocationCookie() {
  getCookies()?.remove(USER_LOCATION_COOKIE_KEY);
}

export function setPalomaPixelData() {
  if (isClient()) {
    const params = new URLSearchParams(window.location.search);
    const paloma_subscriber_id = params.get('paloma_subscriber_id');
    const paloma_conversation_id = params.get('paloma_conversation_id');
    if (paloma_subscriber_id && paloma_conversation_id) {
      browserStorage().setItem(
        PALOMA_AFFILATE_REFERENCE,
        JSON.stringify({ paloma_subscriber_id, paloma_conversation_id })
      );
    }
  }
}

export function getPalomaPixelData() {
  if (isClient()) {
    let paloma = browserStorage().getItem(PALOMA_AFFILATE_REFERENCE);
    paloma = paloma ? JSON.parse(paloma) : null;
    return paloma &&
      paloma?.paloma_subscriber_id &&
      paloma?.paloma_conversation_id
      ? paloma
      : null;
  }

  return null;
}

export function clearPalomaPixelData() {
  if (isClient()) {
    browserStorage().removeItem(PALOMA_AFFILATE_REFERENCE);
  }
}

export function setCJAffiliateCookie() {
  const urlParams = new URLSearchParams(window.location.search);
  const cjeventParam = [...urlParams].find(
    ([key]) => key.toLowerCase() === 'cjevent'
  );
  const cjeventValue = cjeventParam?.[1];
  if (cjeventValue) {
    getCookies().set(CJE_EVENT_COOKIE_KEY, cjeventValue, {
      domain: '.joybird.com',
      path: '/',
      secure: true,
      maxAge: 86400 * 395, // 13 months
      httpOnly: false,
    });
  }
}

export function getTalkativeCustomerId() {
  if (isServer()) {
    return '';
  }

  // talkative_ecs__us__29f15644-956f-45d8-b146-704cc0d865f3__customer_uuid
  // need to find the key that starts with talkative_ecs and ends with customer_uuid
  const store = browserStorage();
  const talkativeCustomerUUIDKey = Object.keys(store).find(
    key => key.startsWith('talkative_ecs') && key.endsWith('customer_uuid')
  );
  return store[talkativeCustomerUUIDKey];
}
