import getEnvValue from './getEnvValue';

const UNKNOWN_ENVIRONMENT = 'unknown';

const environmentName =
  getEnvValue('REACT_APP_ENV_NAME') ??
  getEnvValue('NEXT_PUBLIC_ENV_NAME') ??
  UNKNOWN_ENVIRONMENT;

const getEnvironmentName = () => environmentName;

export default getEnvironmentName;
